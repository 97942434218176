import { useLocation, useSearchParams } from '@remix-run/react';
import { useEffect } from 'react';
import { analytics } from '#app/utils/segment.ts';

export function SegmentAnalytics() {
	const pathname = useLocation();
	const [searchParams] = useSearchParams();

	useEffect(() => {
		analytics.page();
	}, [pathname, searchParams]);

	return null;
}
