import {
	type HeadersFunction,
	json,
	type LinksFunction,
	type LoaderFunctionArgs,
	type MetaFunction,
} from '@remix-run/node';
import {
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	type ShouldRevalidateFunctionArgs,
	useLoaderData,
} from '@remix-run/react';
import { withSentry } from '@sentry/remix';

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { HoneypotProvider } from 'remix-utils/honeypot/react';
import { SegmentAnalytics } from '#app/components/segment.tsx';
import { getLang, getLangSegment, i18n } from '#app/utils/i18n.ts';
import { GeneralErrorBoundary } from './components/error-boundary.tsx';
import { useToast } from './components/toaster.tsx';
import { href as iconsHref } from './components/ui/icon.tsx';
import { EpicToaster } from './components/ui/sonner.tsx';
import fontStyleSheetUrl from './styles/fonts.css?url';
import tailwindStyleSheetUrl from './styles/tailwind.css?url';
import { ClientHintCheck, getHints } from './utils/client-hints.tsx';
import { getEnv } from './utils/env.server.ts';
import { honeypot } from './utils/honeypot.server.ts';
import { combineHeaders, getDomainUrl } from './utils/misc.tsx';
import { useNonce } from './utils/nonce-provider.ts';
import { makeTimings } from './utils/timing.server.ts';
import { getToast } from './utils/toast.server.ts';

export const links: LinksFunction = () => {
	return [
		// Preload svg sprite as a resource to avoid render blocking
		{ rel: 'preload', href: iconsHref, as: 'image' },
		{ rel: 'apple-touch-icon', href: '/favicons/apple-touch-icon.png' },
		{ rel: 'alternate', hrefLang: 'en', href: 'https://upbeat.works' },
		{ rel: 'alternate', hrefLang: 'es', href: 'https://upbeat.works/es' },
		{
			rel: 'alternate',
			hrefLang: 'x-default',
			href: 'https://upbeat.works',
		},
		{
			rel: 'manifest',
			href: '/site.webmanifest',
			crossOrigin: 'use-credentials',
		} as const, // necessary to make typescript happy
		//These should match the css preloads above to avoid css as render blocking resource
		{ rel: 'icon', type: 'image/png', href: '/favicons/favicon-32x32.png' },
		{ rel: 'stylesheet', href: tailwindStyleSheetUrl },
		{ rel: 'preload', href: fontStyleSheetUrl, as: 'style' },
		{ rel: 'stylesheet', href: fontStyleSheetUrl },
	].filter(Boolean);
};

export const meta: MetaFunction<typeof loader> = ({ data, params }) => {
	const locale = getLang(params);
	const langSegment = getLangSegment(locale);
	return [
		{ title: data ? 'Upbeat' : 'Error | Upbeat' },
		{
			name: 'description',
			content: 'Your go-to-place for building digital products.',
		},
		{
			tagName: 'link',
			rel: 'canonical',
			href: `https://upbeat.works${langSegment}`,
		},
	];
};

export async function loader({ request, params }: LoaderFunctionArgs) {
	const timings = makeTimings('root loader');

	const { toast, headers: toastHeaders } = await getToast(request);
	const honeyProps = honeypot.getInputProps();

	const locale = getLang(params);

	return json(
		{
			requestInfo: {
				hints: getHints(request),
				origin: getDomainUrl(request),
				path: new URL(request.url).pathname,
				locale,
			},
			ENV: getEnv(),
			toast,
			honeyProps,
		},
		{
			headers: combineHeaders(
				{ 'Server-Timing': timings.toString() },
				toastHeaders,
			),
		},
	);
}

export function shouldRevalidate({
	currentParams,
	nextParams,
	defaultShouldRevalidate,
}: ShouldRevalidateFunctionArgs) {
	if (currentParams.lng !== nextParams.lng) {
		return true;
	}
	return defaultShouldRevalidate;
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
	const headers = {
		'Server-Timing': loaderHeaders.get('Server-Timing') ?? '',
	};
	return headers;
};

function Document({
	children,
	nonce,
	locale = i18n.fallbackLng,
	env = {},
	allowIndexing = true,
}: {
	children: React.ReactNode;
	nonce: string;
	locale?: string;
	env?: Record<string, string>;
	allowIndexing?: boolean;
}) {
	return (
		<html lang={locale} className="h-full overflow-x-hidden">
			<head>
				<ClientHintCheck nonce={nonce} />
				<Meta />
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width,initial-scale=1" />
				{allowIndexing ? null : (
					<meta name="robots" content="noindex, nofollow" />
				)}
				<Links />
			</head>
			<body className="h-full bg-background text-foreground">
				{children}
				<script
					nonce={nonce}
					dangerouslySetInnerHTML={{
						__html: `window.ENV = ${JSON.stringify(env)}`,
					}}
				/>
				{Boolean(env.GA_MEASUREMENT_ID) && (
					<>
						<script
							nonce={nonce}
							async
							src={`https://www.googletagmanager.com/gtag/js?id=${env.GA_MEASUREMENT_ID}`}
						></script>
						<script nonce={nonce}>
							{`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${env.GA_MEASUREMENT_ID}');`}
						</script>
					</>
				)}
				<ScrollRestoration nonce={nonce} />
				<Scripts nonce={nonce} />
				<SegmentAnalytics />
			</body>
		</html>
	);
}

function App() {
	const data = useLoaderData<typeof loader>();
	const nonce = useNonce();
	const allowIndexing = data.ENV.ALLOW_INDEXING !== 'false';
	useToast(data.toast);

	const { locale } = data.requestInfo;
	const { i18n } = useTranslation();

	useEffect(() => {
		if (locale !== i18n.language) {
			i18n.changeLanguage(locale).catch((err: any) => console.error(err));
		}
	}, [i18n, locale]);

	return (
		<Document
			nonce={nonce}
			allowIndexing={allowIndexing}
			locale={locale}
			env={data.ENV}
		>
			<Outlet />
			<EpicToaster closeButton position="top-center" theme="light" />
		</Document>
	);
}

function AppWithProviders() {
	const data = useLoaderData<typeof loader>();
	return (
		<HoneypotProvider {...data.honeyProps}>
			<App />
		</HoneypotProvider>
	);
}

export default withSentry(AppWithProviders);

export function ErrorBoundary() {
	// the nonce doesn't rely on the loader so we can access that
	const nonce = useNonce();

	// NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
	// likely failed to run so we have to do the best we can.
	// We could probably do better than this (it's possible the loader did run).
	// This would require a change in Remix.

	// Just make sure your root route never errors out and you'll always be able
	// to give the user a better UX.

	return (
		<Document nonce={nonce}>
			<GeneralErrorBoundary />
		</Document>
	);
}
